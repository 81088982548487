import { Button, Input, Textarea } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import {
    BRANCH_CANNED_MESSAGE_OPTIONS,
    BRANCH_CANNED_TYPES_NAMES,
} from "../../../enum";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ValidateForm from "utils/validations/Validator";
import CreateCommentInput from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/TicketDetails/TicketMentions/CreateComment/CreateCommentInput/CreateCommentInput";
import { CANNED_RESOURCE_TYPES } from "components/dashboard/TrainSAM/CannedResponses/enum";
import { EmailForminput } from "components/dashboard/AgentInbox/AgentInboxTickets/EmailPreview/EmailContentGroup/SendEmail/EmailInput/EmailInput";

const { USE_EXISTING, ADD_NEW, NEW_UPDATE } = BRANCH_CANNED_MESSAGE_OPTIONS;

const CannedMessageForm = ({
    handleSuccess,
    isEdit,
    closeModal,
    useExisting,
    isNew,
    handleSelectOption,
    cannedModalType,
    isUpdate,
    guideFlowId,
    selectedCannedResourceId,
}) => {
    const [errorMssg, setErrorMssg] = useState();

    const [cannedResources, setCannedResources] = useState([]);
    const [loadingResources, setLoadingResources] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState();

    const [request, setRequest] = useState({
        cannedResourceName: "",
        cannedResourceBody: "",
        cannedResourceSubject: "",
        cannedResourceType: cannedModalType,
        cannedResourceId: selectedCannedResourceId,
        receipients: []
    });

    const modalCannedTypeName = BRANCH_CANNED_TYPES_NAMES[cannedModalType];

    const selectCannedTypeName =
        BRANCH_CANNED_TYPES_NAMES[`${cannedModalType}_S`];
    const isCannedEmail =
        cannedModalType === CANNED_RESOURCE_TYPES.CANNED_EMAIL;
    const isCannedMention =
        cannedModalType === CANNED_RESOURCE_TYPES.MENTIONS;

    const toastMessage = useContext(ToastContext);

    const getCannedResources = async () => {
        try {
            setErrorMssg();
            setLoadingResources(true);
            const url = apiRoute.getCannedResources;
            const res = await API.get(url, {
                params: {
                    cannedResourceType: cannedModalType,
                    all: true
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                setCannedResources(
                    data?.map(
                        ({ cannedResourceId, cannedResourceName, ...rest }) => {
                            return {
                                label: cannedResourceName,
                                value: cannedResourceId,
                                cannedResourceId,
                                cannedResourceName,
                                ...rest,
                            };
                        }
                    )
                );
                setLoadingResources(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoadingResources(false);
        }
    };

    const handleSelect = (name, value) => {
        if (name === "cannedResourceId") {
            const selectedCannedResource = cannedResources?.find(
                ({ value: cannedResourceId }) => value === cannedResourceId
            )
            return setRequest({
                ...selectedCannedResource,
                receipients: selectedCannedResource?.receipients?.split(",")
            });
        }
        handleSelectOption(value);
    };


    const attachCannedResourceToGuide = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.attachResourceToGuideFlow(guideFlowId);

            const res = await API.post(url, request);
            if (res.status === 201) {
                setLoading(false);
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal?.();
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const updateCannedResource = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.editCannedResource(selectedCannedResourceId);
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const createNewCannedResource = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.createCannedResource;
            const res = await API.post(url, request);

            if (res.status === 201) {
                attachCannedResourceToGuide();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleRecipientEmail = (emails) => {
        setRequest((prev) => ({
            ...prev,
            receipients: emails,
        }));
    }

    const handleMentionChange = ({ target: { value } }) => {
        setRequest({ ...request, cannedResourceBody: value });
        setErrors({ ...errors, cannedResourceBody: "" });
    };

    const handleMentionDeletion = (id) => {
        let receipients = request.receipients.filter(item => item !== id)
        setRequest({ ...request, receipients });
    }

    const handleTagged = (id) => {
        setRequest((prev) => ({
            ...prev,
            receipients: [...(new Set([...prev?.receipients, ...id]))],
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isNew
                ? createNewCannedResource()
                : isUpdate
                    ? updateCannedResource()
                    : attachCannedResourceToGuide();
        }

        setErrors(formErrors);
    };

    useEffect(() => {
        getCannedResources();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedCannedResourceId) {
            const selectedCannedResource = cannedResources?.find(
                ({ cannedResourceId }) =>
                    cannedResourceId === selectedCannedResourceId
            );
            setRequest(
                {
                    ...selectedCannedResource,
                    receipients: selectedCannedResource?.receipients?.split(",")
                }
            );
        }
        // eslint-disable-next-line
    }, [selectedCannedResourceId, cannedResources]);

    useEffect(() => {
        setRequest({ ...request, cannedResourceType: cannedModalType });
        // eslint-disable-next-line
    }, [cannedModalType]);

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            {!isNew && !isUpdate ? (
                <SelectUI
                    name={useExisting ? "cannedResourceId" : ""}
                    label={selectCannedTypeName}
                    darkLabel={true}
                    id={"name"}
                    placeholder={
                        useExisting
                            ? `Select ${modalCannedTypeName}`
                            : ` Select option`
                    }
                    isLoading={loadingResources}
                    options={
                        useExisting
                            ? cannedResources
                            : [
                                {
                                    label: "Choose from existing data base",
                                    value: USE_EXISTING,
                                },
                                {
                                    label: isEdit
                                        ? `Create new ${modalCannedTypeName} update`
                                        : "Add new",
                                    value: isEdit ? NEW_UPDATE : ADD_NEW,
                                },
                            ]
                    }
                    defaultValue={cannedResources?.find(
                        ({ cannedResourceId }) =>
                            cannedResourceId === request?.cannedResourceId
                    )}
                    value={cannedResources?.find(
                        ({ cannedResourceId }) =>
                            cannedResourceId === request?.cannedResourceId
                    )}
                    handleChange={handleSelect}
                    error={errors?.cannedResourceId}
                // autoFocus={autoFocus}
                // serverError={serverError}
                />
            ) : (
                <>
                    <Input
                        grpClass='mb-3'
                        type='text'
                        name='cannedResourceName'
                        label={isNew ? `${selectCannedTypeName} title` : ""}
                        labelClass='text-dark fw-medium'
                        id='cannedResourceName'
                        placeholder={`Name of the ${modalCannedTypeName}`}
                        value={request?.cannedResourceName}
                        onChange={handleChange}
                        isErr={errors?.cannedResourceName}
                        errMssg={errors?.cannedResourceName}
                        autoFocus={true}
                    />

                    {isCannedEmail && (
                        <>
                            <Input
                                grpClass='mb-3 mt-3'
                                type='text'
                                name='cannedResourceSubject'
                                label='Subject'
                                labelClass='text-dark fw-medium'
                                id='cannedResourceSubject'
                                placeholder='Enter Subject'
                                value={request?.cannedResourceSubject}
                                onChange={handleChange}
                                isErr={errors?.cannedResourceSubject}
                                errMssg={errors?.cannedResourceSubject}
                                autoFocus={true}
                                required={false}
                            />

                            <EmailForminput
                                handleRecipientEmail={handleRecipientEmail}
                                handleRemoveEmail={handleMentionDeletion}
                                recipients={request?.receipients}
                            />
                        </>
                    )}

                    {isCannedMention ? <CreateCommentInput
                        comment={request?.cannedResourceBody}
                        handleChange={handleMentionChange}
                        handleTagged={handleTagged}
                        label='Description'
                        labelClass='text-dark fw-medium'
                        grpClass={'full--input'}
                        placeholder='Type Description'
                    /> : <Textarea
                        grpClass='mb-3'
                        type='text'
                        name='cannedResourceBody'
                        label={isNew ? "Message" : ""}
                        labelClass='text-dark fw-medium'
                        id='cannedResourceBody'
                        placeholder={`Description of the ${modalCannedTypeName}`}
                        value={request?.cannedResourceBody}
                        onChange={handleChange}
                        isErr={errors?.cannedResourceBody}
                        errMssg={errors?.cannedResourceBody}
                    />}
                </>
            )}

            <Button
                type='submit'
                text={
                    isUpdate
                        ? "Save and  edit update"
                        : isEdit
                            ? `Updated ${modalCannedTypeName}`
                            : `Add ${modalCannedTypeName}`
                }
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
                disabled={request?.cannedResourceBody === ""}
            />
        </form>
    );
};

export default CannedMessageForm;
